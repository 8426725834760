import '@fancyapps/fancybox';
import Swiper, {
    Navigation, Pagination, Autoplay, Controller, EffectFade
} from 'swiper';
import Plyr from 'plyr';
import Masonry from 'masonry-layout';
import GLightbox from 'glightbox';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';

import mapBox from './inc/hmMaps';
import dataStore from './inc/dataStore';
import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import observeIntersection from './inc/observeIntersection';
import scriptLoader from './inc/scriptLoader';
import devAt from './inc/devAt';
import initTooltip from './inc/hmTooltip';

jQueryBridget('masonry', Masonry, $);
jQueryBridget('imagesLoaded', imagesLoaded, $);

Swiper.use([Navigation, Pagination, Autoplay, Controller, EffectFade]);

const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* observe element intersection +++++++++++++++++++++++++++++*/
    let mapboxinit = false;
    window.initMapGlobal = () => {
        if (mapboxinit === false) {
            mapboxinit = true;
            observeIntersection('.map', initMap, true);
        }
    };

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();
    initTooltip();

    /* Vue observe element intersection +++++++++++++++++++++++++++++*/
    // observeIntersection('#modelhousesFilter', initModelhousesFilter, true);

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* plyr ++++++++++++++++++++++++++++++++++++++++++*/
    window.initPlyr();

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstiges +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /*--- Scroll to ID on Click ---*/
    $('a[href*="#"]:not([href="#"])').on('click touch', (e) => {
        if (window.location.pathname.replace(/^\//, '') === e.currentTarget.pathname.replace(/^\//, '')
                && window.location.hostname === e.currentTarget.hostname) {
            let hashTarget = e.currentTarget.hash;
            hashTarget = hashTarget.length ? hashTarget : $(`[name=${e.currentTarget.hash.slice(1)}]`);
            if (hashTarget.length) {
                $('.open').removeClass('open');
                dataStore.html.stop().animate({
                    scrollTop: $(hashTarget).offset().top - 60
                }, 300);
                e.preventDefault();
            }
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Blocks ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /*--- Steps ---*/
    stepsBg();

    observeIntersection('.step__container', (e, intersected) => {
        if (intersected) {
            $(e.target).addClass('active');
        } else {
            $(e.target).removeClass('active');
        }
    }, false, 0, undefined, '0% 0% -50% 0%');

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Event Listener ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* Data Grounp Level  ++++++++++++++++++++++++++++++++++++++++++*/
    $('[data-groun-level]').on('click touch', (e) => {
        const currentPlan = $(`[data-plan=${$(e.currentTarget).attr('data-groun-level')}]`);
        $('[data-plan]').not(currentPlan).hide();
        currentPlan.show();
    });

    /* Data Grounp Level  ++++++++++++++++++++++++++++++++++++++++++*/
    $('.jobBtn').on('click touch', () => {
        $('#form').show();
        $('#theContent').addClass('hasForm');

        dataStore.html.stop().animate({
            scrollTop: 0
        }, 300);
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstiges ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Menu Sub Menu Length Class  ++++++++++++++++++++++++++++++++++++++++++*/
    const subMenus = $('.sub-menu');
    for (let subMenuindex = 0; subMenuindex < subMenus.length; subMenuindex++) {
        const subMenu = subMenus[subMenuindex];
        const subMenuElLength = $(subMenu).find('li').length;

        if (subMenuElLength < 3) {
            $(subMenu).addClass(`length${subMenuElLength}`);
        }
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();
        stepsScroll();

        if (dataStore.docScroll > 0 && frontpagePopupTriggerFlag === false) {
            frontpagePopupTrigger();
        }
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();

    masonryUpdate();

    /* Steps ++++++++++++++++++++++++++++++++++++++++++*/
    if (dataStore.stepsScrollerLength) {
        dataStore.stepsDateScrollerOffsetTop = $('.stepsScrollContainer').offset().top;
        dataStore.stepsDateScrollerHeiht = $('.stepsScrollContainer').height();
    }
}

$(window).on('load', () => {
    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    const featuredModelHousesSlider = new Swiper('.featuredModelHouses__slider', {
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.featuredModelHouses__slider__nav .swiper-button-next',
            prevEl: '.featuredModelHouses__slider__nav .swiper-button-prev',
        },
    });

    // eslint-disable-next-line
    const featuredModelHousesBgSlider = new Swiper('.featuredModelHousesBg__slider', {
        loop: true,
        effect: 'fade',
        speed: 450,
    });

    if ($('.featuredModelHousesBg__slider').length) {
        featuredModelHousesSlider.controller.control = featuredModelHousesBgSlider;
    }

    const featuredModelHousesTimer = $('.featuredModelHouses__timer');
    featuredModelHousesSlider.on('slideChange', () => {
        featuredModelHousesTimer.removeClass('start');
        setTimeout(() => {
            featuredModelHousesTimer.addClass('start');
        }, 100);
    });

    const imageSwiper = new Swiper('.imageSwiper', {
        spaceBetween: 32,
        navigation: {
            nextEl: '.imageSwiper .swiper-button-next',
            prevEl: '.imageSwiper .swiper-button-prev',
        },
    });

    imageSwiper.on('slideChange', (e) => {
        $(e.$wrapperEl).siblings('.swiper-bottom').find('.swiper-counter').text(e.activeIndex + 1);
    });

    // eslint-disable-next-line
    const historySlider = new Swiper('.historySlider', {
        spaceBetween: 112,
        width: 300,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            enabled: false,
        },
    });

    historySlider.on('slideChange', (e) => {
        const currentSlideIndex = e.activeIndex;
        const historySliderSlides = $('.historySlider .swiper-slide');
        for (let slideIndex = 0; slideIndex < historySliderSlides.length; slideIndex++) {
            const slide = $(historySliderSlides[slideIndex]);
            if (currentSlideIndex >= slideIndex) {
                slide.addClass('gone');
            } else {
                slide.removeClass('gone');
            }
        }

        const currentSlideNav = $(`.historySlider__navigation ul li[data-index=${currentSlideIndex}]`);
        $('.historySlider__navigation ul li').not(currentSlideNav).removeClass('active');
        currentSlideNav.addClass('active');

        lazyloadUpdate();
    });

    $('.historySlider__navigation ul li').on('click touch', (e) => {
        const slideNavIndex = $(e.currentTarget).attr('data-index');
        historySlider.slideTo(slideNavIndex);
    });

    const postSwiperSizer = $('.postSwiperSizer').width();
    // eslint-disable-next-line
    const postSwiper = new Swiper('.postSwiper', {
        spaceBetween: 32,
        width: postSwiperSizer,
        autoHeight: true,
        navigation: {
            nextEl: '.postSwiper .swiper-button-next',
            prevEl: '.postSwiper .swiper-button-prev',
        },
    });

    /* Intersection Observer  ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('.fadeIn', (e, intersected) => {
        if (intersected) {
            $(e.target).not('.vis').addClass('vis');
        }
    }, true);

    observeIntersection('.fadeInRow', (e, intersected) => {
        if (intersected) {
            $(e.target)
                .find('> div, > li, > p')
                .not('.vis')
                .each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
        }
    }, true);

    observeIntersection('.count__number', (e, intersected) => {
        if (intersected) {
            const countNumber = $(e.target);
            for (let countI = 0; countI < countNumber.length; countI++) {
                const count = countNumber[countI];
                const countedNumber = $(count).attr('data-count');

                $({ num: 0 }).animate({ num: countedNumber }, {
                    duration: 2500,
                    easing: 'swing',
                    step: (num) => {
                        $(count).html(Math.floor(num));
                    },
                    complete: () => {
                        $(count).html(countedNumber);
                    }
                });
            }
        }
    }, true);

    /* Masonry  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.$container.imagesLoaded(() => {
        masonryUpdate();
    });

    dataStore.lightbox = GLightbox({
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
        plyr: {
            config: {
                ratio: '16:9', // or '4:3'
                muted: false,
                hideControls: true,
                youtube: {
                    noCookie: true,
                    rel: 0,
                    showinfo: 0,
                    iv_load_policy: 3
                },
                vimeo: {
                    byline: false,
                    portrait: false,
                    title: false,
                    speed: true,
                    transparent: false
                }
            }
        }
    });

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

// Lazyload Update
const masonryUpdate = function masonryUpdate() {
    if (dataStore.$container.length) {
        dataStore.$container.masonry({
            itemSelector: '.item',
            stamp: '.stamp'
        });
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
        callback_loaded: () => {
            masonryUpdate();
        }
    });

    lazyloadUpdate();
})();

// Init Map Box per Scriptloader
function initMap() {
    scriptLoader(
        // 'themeVars' is a localized variable from WordPress
        // eslint-disable-next-line no-undef
        [`${themeVars.assetsUrl}dist/js/mapbox/mapbox-gl.js`],
        mapBox
    );
}

// init plyr
window.initPlyr = () => {
    setTimeout(() => {
        Array.from(document.querySelectorAll('.plyr:not(.plyr--video)')).map((p) => new Plyr(p, {
            controls:
            ['play-large', 'play', 'progress', 'current-time', 'mute',
                'volume', 'captions', 'pip', 'airplay', 'fullscreen']
        }));
    }, 1000);
};

// Vue Init Fucntion
// function initModelhousesFilter() {
//     // load map script and initialize map here
//     scriptLoader(
//         // eslint-disable-next-line no-undef
//         [`${wp_urls.template_dir}/assets/dist/js/modelhouses.js`],
//         () => {}
//     );
// }

/* steps Date Scroller  ++++++++++++++++++++++++++++++++++++++++++*/
let currentScrollAtWindowBottomHalf = '';
let stepsDateScale = 0;
function stepsScroll() {
    if (dataStore.stepsScrollerLength) {
        currentScrollAtWindowBottomHalf = dataStore.docScroll + dataStore.winHeight / 2;

        stepsDateScale = (currentScrollAtWindowBottomHalf - dataStore.stepsDateScrollerOffsetTop)
        / dataStore.stepsDateScrollerHeiht;

        if (stepsDateScale > 1) {
            stepsDateScale = 1;
        } else if (stepsDateScale < 0) {
            stepsDateScale = 0;
        }

        gsap.to('.stepsScoller', {
            scaleY: stepsDateScale,
            x: 0,
            y: 0,
            ease: 'Expo.easeOut',
            overwrite: true,
        });
    }
}

/*--- Steps ---*/
const introContainer = $('.intro');
const stepsContainer = $('.steps');
function stepsBg() {
    if (stepsContainer.length && introContainer.length) {
        dataStore.theContent.append('<div class="bg--grey"></div>');
        const introHeight = introContainer.height();
        const introMarginTop = introContainer.css('margin-top').split('px');
        const introMarginBottom = introContainer.css('margin-bottom').split('px');
        let introMargin = 0;

        introMargin = parseInt(introMarginBottom[0], 10) / 1.5 + parseInt(introMarginTop[0], 10);

        $('#theContent > .bg--grey').height(introMargin + introHeight);
        const steps = $('.step__container');
        for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
            const step = steps[stepIndex];
            const offsetLeft = $(step).offset().left;
            if (offsetLeft > (dataStore.docWidth / 2)) {
                $(step).addClass('right');
            } else {
                $(step).addClass('left');
            }
        }
    }
}

/*--- frontpage Popup Trigger ---*/
let frontpagePopupTriggerFlag = false;
function frontpagePopupTrigger() {
    frontpagePopupTriggerFlag = true;
    // const frontpagePopupTriggerTs = localStorage.getItem('frontpagePopupTriggerTs');
    const dateNow = new Date();
    // let hoursBetweenDates = 0;

    setTimeout(() => {
        // if (frontpagePopupTriggerTs !== null) {
        //     const msBetweenDates = Math.abs(frontpagePopupTriggerTs - dateNow.getTime());
        //     hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        // }

        // if (frontpagePopupTriggerTs === null || hoursBetweenDates > 24) {
        localStorage.setItem('frontpagePopupTriggerTs', dateNow.getTime());
        $('#frontpagePopup__trigger').trigger('click');
        // }
    }, 1000);
}
