import GLightbox from 'glightbox';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import dataStore from './dataStore';

const initTooltip = () => {
    if (dataStore.docWidth >= 768) {
        tippy('[data-tippy-img]', {
            content(reference) {
                const id = reference.getAttribute('data-tippy-img');
                const template = document.getElementById(id);
                return template.innerHTML;
            },
            allowHTML: true,
            interactive: true,
            trigger: 'click', // debug
            onShown() {
                dataStore.lightbox.reload();
            },
        });
    } else {
        const gallery = GLightbox({
            elements: window.planArr,
            gallery: 'plan'
        });

        $('[data-tippy-img]').on('click', (event) => {
            gallery.openAt(parseInt(event.target.getAttribute('data-index'), 10) - 1);
        });
    }

    tippy('[data-tippy-text]', {
        content(reference) {
            const id = reference.getAttribute('data-tippy-text');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true,
        // trigger: 'click', // debug
    });
};

export default initTooltip;
